h1 {
  @extend %heading;
  @include font-size($h1-font-size);
  font-weight: $font-weight-bold;
}

h2 {
  @extend %heading;
  @include font-size($h2-font-size);
  font-weight: $font-weight-light;
}

h3 {
  @extend %heading;
  @include font-size($h3-font-size);
  font-weight: $font-weight-bold;
}

h4 {
  @extend %heading;
  @include font-size($h4-font-size);
  font-weight: $font-weight-light;
}

h5 {
  @extend %heading;
  @include font-size($h5-font-size);
  font-weight: $font-weight-bold;
}

h6 {
  @extend %heading;
  @include font-size($h6-font-size);
  font-weight: $font-weight-light;
}

.intro-text {
  @include wysiwyg;

  * {
    color: $brand-gray;

    @include media-breakpoint-up(lg) {
      @include font-size($h4-font-size);
    }
  }
}
