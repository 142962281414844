@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/pagination/pagination';
@import 'node_modules/swiper/modules/effect-fade/effect-fade';

.slider-layout {
  .swiper-pagination {
    pointer-events: none;
  }

  .swiper-pagination-bullets {
    bottom: map-get($spacers, 3);
  }

  .swiper-pagination-bullet {
    pointer-events: all;
    opacity: 1;
    background: $brand-white;
    width: map-get($spacers, 5);
    height: $spacer * 0.25;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: $brand-red;
  }

  .custom-swiper-buttons {
    pointer-events: none;
    --custom-swiper-button-height: calc(#{$spacer} * 2);
    --custom-swiper-button-width: calc(#{$spacer} * 5);
    position: relative;
    min-height: calc(2 * var(--custom-swiper-button-height));
    margin-top: calc(-1 * var(--custom-swiper-button-height));
    z-index: 10;

    .custom-swiper-button-next {
      background-color: $brand-red;
      width: var(--custom-swiper-button-width);
      bottom: 0;
      right: calc(var(--custom-swiper-button-height));

      &::after {
        position: absolute;
        content: '';
        width: var(--custom-swiper-button-height);
        height: var(--custom-swiper-button-height);
        background: linear-gradient(to bottom right, $brand-red 0%, $brand-red 50%, transparent 50%, transparent 100%);
        top: 0;
        right: calc(-1 * var(--custom-swiper-button-height));
      }
    }

    .custom-swiper-button-prev {
      background-color: $brand-white;
      width: var(--custom-swiper-button-width);
      top: 0;
      right: calc(var(--custom-swiper-button-height) + #{map-get($spacers, 3)});

      &::after {
        position: absolute;
        content: '';
        width: var(--custom-swiper-button-height);
        height: var(--custom-swiper-button-height);
        background: linear-gradient(to top left, $brand-white 0%, $brand-white 50%, transparent 50%, transparent 100%);
        top: 0;
        left: calc(-1 * var(--custom-swiper-button-height));
      }
    }

    .custom-swiper-button-next, .custom-swiper-button-prev {
      pointer-events: all;
      content: '';
      position: absolute;
      height: var(--custom-swiper-button-height);
      border: none;
      filter: drop-shadow(0 0 1px $brand-coloured-gray);
    }
  }

  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
