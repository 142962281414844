.footer-slim {
  @include link($color: $brand-white, $hover-decoration: none);

  @include media-breakpoint-up(lg) {
    @include link($color: $brand-white, $hover-decoration: none);
  }

  color: $footer-text-color;
  font-size: $footer-slim-font-size;
  background: linear-gradient(to top, $footer-gradient-start 0%, $footer-gradient-end 100%);

  .company-name {
    font-size: $footer-fat-font-size;
  }
}
