// Body
$body-max-with: 1920px;

// Trasitions
$link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

// Footer
$footer-fat-font-size: $font-size-base * 0.7;
$footer-slim-font-size: $font-size-base * 0.6;

// Navigation
$desktop-nav-width: 240px;
$desktop-nav-box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.25);
$mobile-nav-height: $spacer * 4;
$mobile-nav-height-reduced: $spacer * 3;
$mobile-nav-box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.25);
$meta-nav-height: $spacer * 3;

// Form
$form-select-focus-box-shadow: none;
