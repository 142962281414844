#navbar-toggle-button {
  --height: 22px;
  --width: 22px;
  --line-height: 2px;
  --offset: calc((var(--height) - (3 * var(--line-height))) / 4);
  --transition-duration: 100ms;

  display: flex;
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  .menuicon {
    position: relative;
    pointer-events: none;
    margin: 0;
    padding: 0;
    height: var(--height);
    width: var(--width);

    .menuicon-top {
      @include menuicon($height: var(--line-height), $color: $brand-anthracite);
      top: calc(var(--offset));
      transition: transform var(--transition-duration) ease-in-out,
      top var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    .menuicon-center {
      @include menuicon($height: var(--line-height), $color: $brand-anthracite);
      top: calc((2 * var(--offset)) + var(--line-height));
      transition: opacity var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    .menuicon-bottom {
      @include menuicon($height: var(--line-height), $color: $brand-anthracite);
      top: calc((3 * var(--offset)) + (2 * var(--line-height)));
      transition: transform var(--transition-duration) ease-in-out,
      top var(--transition-duration) var(--transition-duration) ease-in-out;
    }

    &.navbar-open {
      .menuicon-top {
        transition: top var(--transition-duration) ease-in-out,
        transform var(--transition-duration) var(--transition-duration) ease-in-out;
        top: calc((2 * var(--offset)) + var(--line-height));
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .menuicon-center {
        transition: opacity var(--transition-duration) ease-in-out;
        opacity: 0;
      }

      .menuicon-bottom {
        transition: top var(--transition-duration) ease-in-out,
        transform var(--transition-duration) var(--transition-duration) ease-in-out;
        top: calc((2 * var(--offset)) + var(--line-height));
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  }
}

.base-layout {
  .base-navigation-mobile {
    transition: height 250ms ease-out;

    .nav-mobile {
      .nav-mobile-logo-wrapper {
        height: $mobile-nav-height;
        text-align: center;
        overflow: hidden;
        padding: ($spacer * 0.5) 0 ($spacer * 0.5) 0;
        transition: height 250ms ease-out, padding 250ms ease-out;
      }
    }

    &.reduced {
      transition: height 250ms ease-out;
      height: $mobile-nav-height-reduced;

      .nav-mobile {
        .nav-mobile-logo-wrapper {
          height: $mobile-nav-height-reduced;
          padding: ($spacer * 0.25) 0 ($spacer * 0.25) 0;
          transition: height 250ms ease-out, padding 250ms ease-out;
        }
      }
    }
  }
}

#nav-mobile-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-modal;
  overflow: hidden;
  height: 0;
  transition: height 250ms ease-out;
  font-size: $font-size-base * 0.8;

  &.open {
    height: 100%;
    transition: height 250ms linear;
  }

  .nav-mobile-container-inner {
    transition: transform 250ms ease 0s;
    background-color: $brand-white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: $mobile-nav-height;
    overflow: scroll;

    &.nav-mobile-container-enter {
      transform: translateX(100vw);
    }

    a {
      color: $brand-black;

      &.nav-mobile-item {
        position: relative;
        display: flex;
        align-items: center;

        span {
          flex-grow: 1;
        }

        i {
          font-size: $font-size-base * 0.6;
        }
      }

      &:hover {
        color: $brand-gray;
        text-decoration: none;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    li {
      position: relative;
      white-space: nowrap;
      width: 100%;
    }
  }
}
