.base-layout {
  min-height: 100vh;

  .base-navigation-desktop {
    background-color: $brand-white;
    box-shadow: $desktop-nav-box-shadow;
    position: fixed;
    min-height: 100vh;
    width: $desktop-nav-width;
    display: none;
    z-index: $zindex-fixed;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .content-area {
    background-color: $brand-white;
    margin-left: initial;
    margin-top: $mobile-nav-height;

    .base-navigation-mobile {
      background-color: $brand-white;
      box-shadow: $mobile-nav-box-shadow;
      z-index: $zindex-tooltip;
    }

    .base-meta-navigation {
      background-color: $brand-coloured-gray;
      height: $meta-nav-height;
      z-index: $zindex-tooltip;
      transition: height 250ms;
    }

    @include media-breakpoint-up(lg) {
      margin-left: $desktop-nav-width;
      width: calc(100% - #{$desktop-nav-width});
      margin-top: 0;
    }

    .base-navigation-mobile {
      height: $mobile-nav-height;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
}
