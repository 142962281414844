.base-navigation-desktop {
  nav {
    font-size: $font-size-base * 0.8;

    ul {
      li {
        a {
          text-decoration: none;
        }
      }
    }

    .base-element {
      position: relative;
      // compensate for sidebar border
      border-right: 1px solid transparent;
      width: 100%;
      color: $brand-black;
      font-size: $font-size-base * 0.7;

      &:hover {
        border-right: 1px solid transparent;
        color: lighten($brand-black, 35%)
      }

      &.active {
        font-weight: $font-weight-bold;
      }
    }

    .parent-element {
      font-weight: $font-weight-bold;

      .parent-element-nav {
        display: none;
        font-size: $font-size-base * 0.7;

        ul {
          li {
            white-space: nowrap;
          }
        }
      }

      &:hover {
        .parent-element-nav {
          position: absolute;
          display: block;
          top: 0;
          font-weight: $font-weight-normal;
          left: $desktop-nav-width;
          min-width: 100%;
        }
      }

      @for $i from 1 through 9 {
        &.level-#{$i} {
          @if $i == 1 {
            @include sidebar-level-element(
              $color: $brand-white,
              $hover-color: darken($brand-white, 35%),
              $background-color: $complementary-charcoal-blue
            )
          } @else {
            @include sidebar-level-element(
              $color: $brand-black,
              $hover-color: lighten($brand-black, 35%),
              $background-color: lighten($brand-gray, ($i + 1) * 8%)
            )
          }
        }
      }
    }
  }
}
