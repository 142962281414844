@mixin link(
  $color: $link-color,
  $background-color: none,
  $decoration: $link-decoration,
  $hover-color: $link-hover-color,
  $hover-decoration: $link-hover-decoration,
  $hover-background-color: none,
) {
  a {
    color: $color;
    text-decoration: $decoration;
    background-color: $background-color;
    @include transition($link-transition);

    &:hover {
      color: $hover-color;
      text-decoration: $hover-decoration;
      background-color: $hover-background-color;
    }
  }
}

@mixin sidebar-level-element(
  $color: $brand-black,
   $hover-color: darken($color, 35%),
   $background-color: $brand-white
) {
  &:hover {
    border-right: 1px solid $background-color;
  }

  &::after {
    color: $color;
  }

  a {
    display: flex;
    align-items: center;
    background-color: $background-color;
    color: $color;

    span {
      flex-grow: 1;
    }

    i {
      font-size: $font-size-base * 0.6;
      padding-left: $spacer * 0.5;
      padding-right: $spacer * 0.5;
    }

    &:hover {
      color: $hover-color;
    }
  }

  .parent-element-nav {
    background-color: $background-color;
  }
}

@mixin menuicon($height, $color) {
  position: absolute;
  display: block;
  width: 100%;
  height: $height;
  background-color: $color;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

@mixin link-unset(
  $color: unset,
  $border-bottom: unset,
  $background-color: unset,
  $padding-bottom: unset,
  $hover-color: unset,
  $transition: unset,
  $text-decoration: unset,
  $content-before: unset,
  $content-after: unset,
  $hover-border-bottom: unset,
  $hover-background-color: unset,
  $hover-padding-bottom: unset,
  $hover-transition: unset,
  $hover-text-decoration: unset,
) {
  color: $color;
  border-bottom: $border-bottom;
  background-color: $background-color;
  padding-bottom: $padding-bottom;
  transition: $transition;
  text-decoration: $text-decoration;

  &:hover {
    color: $hover-color;
    border-bottom: $hover-border-bottom;
    background-color: $hover-background-color;
    padding-bottom: $hover-padding-bottom;
    transition: $hover-transition;
    text-decoration: $hover-text-decoration;
  }

  &::before {
    content: $content-before;
  }

  &::after {
    content: $content-after;
  }
}

@mixin wysiwyg() {
  ul {
    list-style: none;

    li {
      margin-bottom: map-get($spacers, 2);
      position: relative;

      &::marker {
        color: $brand-red;
      }

      &::before {
        position: absolute;
        left: -20px;
        top: 12px;
        color: $brand-red;
        @include font-size($font-size-base * 0.3);
        content: ' \25CF';
      }
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid $brand-red;
    font-style: italic;
    color: $brand-gray;
  }
}

.wysiwyg {
  @include wysiwyg;
}

@mixin hero-ratios() {
  --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

  @include media-breakpoint-up(sm) {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};
  }

  @include media-breakpoint-up(xl) {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '21x9')};
  }
}

.hero-ratios {
  @include hero-ratios;
}

@mixin collapsible-chevron($icon-class) {
  .collapsible-layout-header {
    .fa-chevron-right {
      color: $dark;
      transform: rotate(90deg);
      transition: transform 0.35s ease, color 0.35s ease;
    }

    &.collapsed {
      .fa-chevron-right {
        color: $red;
        transform: rotate(0deg);
        transition: transform 0.35s ease, color 0.35s ease;
      }
    }
  }

  .#{$icon-class} {
    min-width: 2rem;
  }
}

@mixin alternate-teasers() {
  &:nth-child(odd) {
    .teaser-element {
      &.alternate {
        .teaser-element-text-wrapper {
          align-self: flex-start;
        }
      }
    }
  }

  &:nth-child(even) {
    .teaser-element {
      &.alternate {
        .teaser-element-text-wrapper {
          align-self: flex-end;
        }

        .teaser-element-img-wrapper {
          align-self: flex-start;
        }
      }
    }
  }
}
