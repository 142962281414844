.block-list {
  .block-list-elements {
    @include font-size($font-size-base * 1.3);

    @include media-breakpoint-up(lg) {
      @include font-size($font-size-base * 1.5);
    }

    i {
      color: $brand-red;
      @include font-size($font-size-base * 1.8);
    }
  }
}
