.teaser-element {
    position: relative;

    .teaser-element-img-wrapper {
        align-self: flex-end;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 80%;
        }

        .teaser-element-img-ratios {
            --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

            @include media-breakpoint-up(sm) {
                --bs-aspect-ratio: #{map-get($aspect-ratios, '4x3')};
            }

            @include media-breakpoint-up(xl) {
                --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};

                &.teaser-element-img-ratios-small {
                    --bs-aspect-ratio: #{map-get($aspect-ratios, '4x3')};
                }
            }
        }
    }

    .teaser-element-text-wrapper {
        width: 100%;
        position: relative;

        @include media-breakpoint-up(md) {
            position: absolute;
            width: 50%;
            top: 70%;
        }

        .teaser-element-text-heading {
            @include media-breakpoint-up(xl) {
                @include font-size($h4-font-size);
            }
        }
    }
}
