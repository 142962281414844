#cookie-consent-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2000;
  display: none;
}

#cookie-consent {
  position: fixed;
  pointer-events: none;
  z-index: 2010;
  bottom: 0;
  left: 0;
  right: 0;

  .cookie-consent-info-window {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $brand-white;
    width: 100%;
  }
}
