.block-iframe {
  .iframe-ratios {
    --bs-aspect-ratio: #{map-get($aspect-ratios, '1x1')};

    @include media-breakpoint-up(sm) {
      --bs-aspect-ratio: #{map-get($aspect-ratios, '16x9')};
    }

    @include media-breakpoint-up(xl) {
      --bs-aspect-ratio: #{map-get($aspect-ratios, '21x9')};
    }
  }
}
