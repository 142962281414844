// Brand
$brand-red: #EE3126;
$brand-anthracite: #4A4A4A;
$brand-gray: #777777;
$brand-coloured-gray: #E8E8E8;
$brand-white: #FFFFFF;
$brand-black: #000000;
$surface-color: $brand-white;

// Complementary
$complementary-charcoal-blue: #2E3440;
$complementary-elm: #D0B580;
$complementary-warm-gray: #E4E1CC;
$complementary-surface: #FAF9F5;

// Feedback
$feedback-error: #990033;
$feedback-success: #5F9900;
$feedback-information: #6F9DA8;
$feedback-warning: #CC7700;

// Footer
$footer-text-color: #626770;
$fat-footer-text-color: #969AA0;
$footer-gradient-start: $complementary-charcoal-blue;
$footer-gradient-end: #171A20;
