.gmap-wrapper {
  position: relative;
  margin: 0;

  .pois {
    pointer-events: none;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .poi {
      display: block;
      pointer-events: initial;
      background-color: $white;
      position: absolute;
      bottom: 0;
      right: 0;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }

      button {
        content: '';
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23DC0023'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3C/svg%3E") no-repeat center;
        background-size: 24px !important;
        width: 24px;
        height: 24px;
        position: absolute;
        top: map-get($spacers, 1);
        right: map-get($spacers, 1);
        border: none;

        &:focus {
          outline: 0;
        }
      }
    }
  }
}
