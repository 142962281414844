@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-500 {
  animation: fade-in 500ms;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out-500 {
  animation: fade-out 500ms;
}

@keyframes meta-nav-decorator-animation {
  0% {
    height: 0;
  }
  100% {
    height: 4px;
  }
}
