.block-title-text {
  a {
    &.btn {
      margin-right: map-get($spacers, 3);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
