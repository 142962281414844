.latest-news-and-events-entry {
  .latest-news-teaser-text {
    position: relative;

    p {
      max-height: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include media-breakpoint-up(sm) {
        -webkit-line-clamp: 11;
      }

      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 3;
      }

      @include media-breakpoint-up(xl) {
        -webkit-line-clamp: 8;
      }

      @include media-breakpoint-up(xxl) {
        -webkit-line-clamp: 4;
      }
    }
  }
}
