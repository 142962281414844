.footer-top {
  margin-top: map-get($spacers, 7);

  .brand-logo {
    min-width: map-get($spacers, 7);
    min-height: 35px;
    z-index: $zindex-dropdown;

    img {
      height: 35px;
      width: auto;
    }
  }

  .brand-claim {
    z-index: $zindex-dropdown;
  }

  .footer-top-inner-wrapper {
    --decorator-offset: 5px;
    --decorator-height: calc((#{$spacer} * 0.7) + var(--decorator-offset));
    --decorator-width: calc((#{$spacer} * 5 + var(--bs-gutter-x, 0.75rem)) + var(--decorator-offset));
    --decorator-y-position: calc(-1 * var(--decorator-height) + var(--decorator-offset));
    --decorator-x-position: calc((-1 * var(--bs-gutter-x, 0.75rem)) - var(--decorator-offset));

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    position: relative;

    &::before {
      position: absolute;
      z-index: 0;
      display: block;
      content: '';
      width: var(--decorator-width);
      height: var(--decorator-height);
      bottom: var(--decorator-y-position);
      left: var(--decorator-x-position);
      border-top: var(--decorator-height) solid $brand-white;
      border-right: calc(var(--decorator-height) * 2) solid transparent;
    }
  }
}
