.block-google-map {
  .gmap-wrapper, .gmap {
    width: 100%;
    height: 100%;
  }

  .map-ratio-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 60%;
    }
  }

  .map-address-information {
    position: relative;

    @include media-breakpoint-up(md) {
      position: absolute;
      left: 50%;
      top: 10%;
      width: 50%;
    }
  }
}

