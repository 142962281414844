.base-meta-navigation {
  .nav-meta-search {
    .nav-meta-search-form-wrapper {
      animation-name: fade-in;
      animation-fill-mode: forwards;
      animation-duration: 250ms;

      &.hidden {
        animation-name: fade-out;
        animation-fill-mode: forwards;
        animation-duration: 250ms;
      }
    }

    .nav-meta-search-toggle {
      i {
        width: 16px;
        height: auto;
        animation-name: fade-in;
        animation-fill-mode: forwards;
        animation-duration: 250ms;

        &.hidden {
          animation-name: fade-out;
          animation-fill-mode: forwards;
          animation-duration: 250ms;
        }
      }
    }
  }

  .nav-meta-links {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: flex-end;

    ul {
      height: 100%;
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size($font-size-base * 0.8);

        &.nav-meta-entry {
          position: relative;
          height: 100%;

          a {
            display: flex;
            height: 100%;
            align-items: center;
          }

          &:hover {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              background-color: $brand-black;
              animation-name: meta-nav-decorator-animation;
              animation-duration: 150ms;
              animation-fill-mode: forwards;
              height: 0;
            }
          }
        }

        .nav-meta-dropdown-entry {
          position: relative;
          height: 100%;

          &.active {
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: $brand-red;
              animation-name: meta-nav-decorator-animation;
              animation-duration: 150ms;
              animation-fill-mode: forwards;
              height: 0;
            }
          }

          button {
            height: 100%;

            &.btn {
              &.nav-meta-dropdown-entry-control {
                border: 0;
              }
            }
          }

          .nav-meta-dropdown-entry-content {
            position: absolute;
            white-space: nowrap;
            background-color: $brand-white;
            box-shadow: $box-shadow;
            overflow: hidden;
            display: none;

            &.align-right {
              right: 0;
            }

            &.align-left {
              left: 0;
            }

            &:focus {
              outline: none;
            }

            &[aria-expanded="true"], &.expanded {
              display: block;
            }

            ul {
              flex-direction: column;

              li {
                justify-content: flex-start;

                a {
                  width: 100%;

                  &:hover {
                    background-color: $brand-coloured-gray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
