.block-teasers {
    .block-teaser-row {
        .block-teaser-col {
            &:not(.col-xl-6) {
                @include alternate-teasers;
            }

            &.col-xl-6 {
                @include media-breakpoint-down(xl) {
                    @include alternate-teasers;
                }

                .teaser-element {
                    .teaser-element-text-heading {
                        @include media-breakpoint-up(xl) {
                            font-size: $font-size-base * 0.8;
                        }

                        @include media-breakpoint-up(xxl) {
                            @include font-size($h4-font-size * 0.8);
                        }
                    }

                    .teaser-element-text-wrapper {
                        top: 60%;
                        bottom: unset;

                        @include media-breakpoint-up(xl) {
                            top: unset;
                            bottom: -1 * $spacer;
                        }
                    }
                }
            }
        }
    }
}
