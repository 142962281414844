$font-family-sans-serif: roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: roboto-condensed, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.9;

$lead-font-weight: 400;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $brand-red,
  "secondary": $brand-anthracite,
  "success": $feedback-success,
  "info": $feedback-information,
  "warning": $feedback-warning,
  "danger": $feedback-error,
  "brand-red":$brand-red,
  "brand-gray": $brand-gray,
  "brand-anthracite":$brand-anthracite,
  "brand-coloured-gray":$brand-coloured-gray,
  "brand-white": $brand-white,
  "brand-black": $brand-black,
  "complementary-charcoal-blue": $complementary-charcoal-blue,
  "complementary-elm": $complementary-elm,
  "complementary-warm-gray": $complementary-warm-gray,
  "complementary-surface": $complementary-surface,
);

$primary: $brand-red;
$secondary: $brand-anthracite;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
);

// Layout
$enable-cssgrid: true;

// Links
$link-color: $brand-red;
$link-hover-color: $brand-red;
$link-decoration: none;
$link-hover-decoration: underline;

// Button Link
$btn-link-color: $brand-red;
$btn-link-hover-color: $brand-red;

// Margins
$enable-negative-margins: true;

// Accordion
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-button-padding-x: 0;
$accordion-body-padding-x: 0;
$accordion-bg: transparent;
$accordion-color: $brand-black;
$accordion-icon-width: 1rem;

$accordion-button-color: $brand-black;
$accordion-icon-color: $brand-black;
$accordion-button-icon: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-up" class="svg-inline--fa fa-chevron-up fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$accordion-icon-color}" d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"></path></svg>');

$accordion-button-active-bg: transparent;
$accordion-button-active-color: $brand-red;
$accordion-icon-active-color: $brand-red;
$accordion-button-active-icon: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="chevron-up" class="svg-inline--fa fa-chevron-up fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$accordion-icon-active-color}" d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"></path></svg>');

// Buttons
$btn-border-width: 2px;
$btn-font-size: $font-size-base * 0.8;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-padding-y-lg: .8rem;
$btn-padding-x-lg: 1rem;
$btn-font-size-lg: $btn-font-size;

  // Inputs
$input-color: $brand-anthracite;
$input-focus-color: $brand-anthracite;
$input-border-color: lighten($brand-anthracite, 50%);
$input-focus-border-color: lighten($brand-anthracite, 50%);
$input-font-size: $font-size-base * 0.8;
$input-focus-box-shadow: none;

