.accordion-button {
  font-weight: $font-weight-light;
  font-size: $font-size-base * 1.2;

  &::after {
    transform: rotate(90deg) !important;
  }

  &.collapsed {
    border-bottom: 1px solid $brand-coloured-gray;
  }

  &:not(.collapsed) {
    &::after {
      transform: rotate(180deg) !important;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.btn {
  @each $key, $color in $theme-colors {
    // add special handling by key if required
    &.btn-#{$key} {
      color: $brand-white;
    }

    &:hover, &:active, :focus {
      &.btn-#{$key} {
        box-shadow: none;
        background-color: darken($color, 10%);
        border-color: darken($color, 10%);
      }

      &.btn-outline-#{$key} {
        background-color: transparent;
        color: darken($color, 10%);;
        border-color: darken($color, 10%);;
        box-shadow: inset 0 0 0 1px darken($color, 10%);;
      }
    }
  }
}

.bg-brand-anthracite {
  color: $brand-white;
}

.bg-complementary-elm {
  color: $brand-white;
}

.form-control {
  &::placeholder {
    font-weight: $font-weight-light;
    font-style: italic;
  }
}
