#home-hero {
  position: relative;
  --content-border-color: 1px solid #{transparentize($brand-white, 0.8)};
  --pagination-wrapper-height: calc(3 * #{$spacer});

  .swiper-slide {
    transition: opacity 500ms;
  }

  .slide-text {
    display: flex;
    color: $brand-white;
    z-index: $zindex-dropdown;
    position: relative;
    // the url must be relative to the main _entry.scss file
    background: url('../../../_assets/images/home-decroche-slider.svg') no-repeat calc(50% - 3rem) 0;
    padding: $spacer 0 $spacer 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $brand-anthracite;

    @include media-breakpoint-up(lg) {
      position: absolute;
      padding: (1.5 * $spacer) 0 var(--pagination-wrapper-height) 0;
      background-color: transparent;
    }

    .slide-text-heading {
      flex-grow: 1;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 0;
      text-shadow: 0 8px 16px #{transparentize($brand-black, 0.8)}, 0 4px 8px #{transparentize($brand-black, 0.9)};

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base * 2.5;
        margin-bottom: $spacer * 0.5;
      }
    }

    .slide-text-subheading {
      display: none;

      @include media-breakpoint-up(lg) {
        display: initial;
      }
    }

    .slide-text-actions {
      .btn.btn-lg {
        font-size: $font-size-base * 0.6;
        font-weight: $font-weight-bold;
        color: $brand-white;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-base * 0.9;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }

  .swiper-pagination-wrapper {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $brand-white;
    z-index: $zindex-dropdown;
    border-top: var(--content-border-color);
    //background-color: var(--content-bg-color);
    height: var(--pagination-wrapper-height);

    .swiper-pagination-custom {
      bottom: 0;
      left: unset;
      width: 100%;
      position: relative;
    }

    @include media-breakpoint-up(lg) {
      display: initial;
    }

    .swiper-pagination {
      display: flex;
      transition: 300ms opacity;

      &.swiper-pagination-hidden {
        opacity: 0;
      }

      &.swiper-pagination-clickable {
        .home-swiper-pagination-bullet {
          position: relative;
          display: flex;
          align-items: center;
          flex-grow: 1;
          padding-left: $spacer;
          font-size: $font-size-base * 0.7;
          font-weight: $font-weight-bold;
          cursor: pointer;
          border-left: var(--content-border-color);

          &:last-child {
            border-left: var(--content-border-color);
            border-right: var(--content-border-color);
          }

          &.swiper-pagination-bullet-active {
            background-color: rgba($brand-white, 0.2);
          }
        }
      }
    }
  }

  .slider-text-background {
    background-position: calc(50% + .5rem) 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 256px;
    background: var(--slider-text-background, #2e3440)
  }
}
