@import '../../_assets/styles/mixins';
@import '../../_assets/styles/colors';

.footer-fat {
  color: $fat-footer-text-color;
  font-size: $footer-fat-font-size;
  @include link($color: $fat-footer-text-color, $hover-decoration: none);

  .social-media-platform-wrapper {
    @include link(
      $color: $complementary-charcoal-blue,
      $background-color: $fat-footer-text-color,
      $hover-color: $complementary-charcoal-blue,
      $hover-background-color: $brand-white,
    );

    .social-media-platform {
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 16px;
      text-align: center;
    }
  }

  .call-to-action-box {
    .call-to-action-box-icon {
      display: inline;

      i {
        font-size: $font-size-base;

        @include media-breakpoint-up(md) {
          font-size: $font-size-base * 2;
        }
      }
    }

    .call-to-action-box-text {
      font-weight: $font-weight-bold;
    }
  }
}
