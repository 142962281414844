.block-team {
  .team-employee-position {
    min-height: map-get($spacers, 5);

    p {
      color: $brand-black;
      font-size: $font-size-base * 0.9;
    }
  }

  .team-emplyoee-details {
    h3 {
      color: $brand-red;
      font-size: $font-size-base * 1.2;
    }

    i {
      color: $brand-red;
    }

    a {
      text-decoration: underline;
      font-size: $font-size-base * 0.8;
    }

    span {
      color: $brand-black;
      font-size: $font-size-base * 0.9;
    }
  }
}
